/** @jsx jsx */
import { Link } from 'gatsby';
import React from 'react';
import { jsx, Text } from 'theme-ui';
import PrimaryLayout from '../components/layout/primary/primary';
import SEO from '../components/seo';
import Image from '../images/placenta-baby-crawling.png';

const UnauthorisedPage: React.FC<{}> = () => {
	return (
		<PrimaryLayout
			homeLink="/"
		>
			<SEO title="401 Unauthorised Access" />

			<div sx={styles.wrapper} className="container">
				<div className="row">
					<div className="col-md-12">
						<img src={Image} sx={styles.image} alt="Concerned looking baby" />
						<Text as="h3" sx={styles.wrapper.subTitle}>Access Denied</Text>
						<Text as="p" sx={styles.wrapper.errorText}>Sorry, you are not authorised to complete that action.<br /> Please contact us if you feel this is a mistake</Text>
						<Link to="/contact" sx={styles.wrapper.goback}>Contact</Link>
					</div>
				</div>
			</div>
		</PrimaryLayout>
	);
};

const styles = {
	wrapper: {
		maxWidth: '950px',
		paddingTop: '50px',
		paddingBottom: '50px',
		textAlign: 'center',
		'@media only screen and (max-width: 800px)': {
			paddingTop: '0',
			paddingBottom: '0',
		},
		'@media only screen and (min-width: 801px) and (max-width: 1280px)': {
			paddingTop: '20px',
			paddingBottom: '20px',
		},
		subTitle: {
			fontSize: '26px',
			lineHeight: '39px',
			color: '#222',
			fontWeight: 'normal',
			marginBottom: '10px',
		},
		errorText: {
			fontSize: '14px',
			lineHeight: '22px',
			color: '#656766',
			marginBottom: '2em'
		},
		goback: {
			width: '100%',
			maxWidth: '340px',
			fontWeight: '600',
			borderRadius: '3em',
			padding: '1em 2em',
			display: 'inline-block',
			color: '#fff',
			backgroundColor: '#00d4c5',
			bordercolor: '#685f52',
			margin: '0 auto',
			textDecoration: 'none',
			'&:hover': {
				color: '#FFF',
			},
		}
	},
	image: {
		width: '350px',
		marginTop: '20px',
		marginBottom: '20px'
	}
}

export default UnauthorisedPage;
